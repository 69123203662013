import { useEffect, useState } from 'react'

import { AxiosError } from 'axios'
import type { NextPage } from 'next'
import { useRouter } from 'next/router'

import ErrorLayout from '@/layouts/ErrorLayout'
import ory from '@/utils/ory'

const ErrorPage: NextPage = () => {
  const [error, setError] = useState<AxiosError>()

  // Get ?id=... from the URL
  const router = useRouter()
  const { id, message, title, error_description, skip_go_back } = router.query
  const routerTitle = Array.isArray(title) ? title.join(' ') : title

  useEffect(() => {
    // If the router is not ready yet, or we already have an error, do nothing.
    if (!router.isReady || error || !id) {
      return
    }

    ory
      .getFlowError({ id: String(id) })
      .then(({ data }) => setError(data.error as AxiosError))
  }, [id, router, router.isReady, error])

  return (
    <ErrorLayout
      title='An error occurred'
      headline={error?.name || routerTitle || 'An error occurred.'}
      message={
        error?.message ||
        message?.toString() ||
        error_description ||
        'An error occurred'
      }
      skipGoBack={skip_go_back === 'true'}
    />
  )
}

export default ErrorPage
